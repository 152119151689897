import React from "react";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  HttpLink,
  from,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";

const errorLink = onError(({ graphqlErrors, networkError }) => {
  if (graphqlErrors) {
    graphqlErrors.map(({ message, location, path }) => {
      alert(`Graphql error ${message}`);
    });
  }
});

const endpointLink = from([
  errorLink,
  new HttpLink({
    uri: `${process.env.NEXT_PUBLIC_BACK_END_URL}/graphql`,
    credentials: "include",
  }),
]);

const client = new ApolloClient({
  link: endpointLink,
  cache: new InMemoryCache(),
});

export const ApolloProviders = ({ children }) => {
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
