import { configureStore } from "@reduxjs/toolkit";
import cartSlice from "./reducer/CartReducer";

export default configureStore({
  reducer: {
    Cart: cartSlice,
  },
  devTools: process.env.NODE_ENV === "development",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});
